/* tslint:disable:no-default-export */

import React from 'react';

import { IconDisplay } from '../../../components';
import LayoutComponents from '../../../components/capital/Layouts/LayoutComponents';

export default () => (
  <LayoutComponents
    activeTab="styles"
    title="Icon Library"
    description="All icons supplied by Capital Design System."
    storybookUrl="..."
    wide={true}
  >
    <IconDisplay />
  </LayoutComponents>
);
